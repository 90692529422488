<template>
  <div class="tire-review">
    <el-card class="box-card">
      <el-form
        :model="review"
        label-width="120px">
        <el-form-item label="Статус">
          <el-switch
            v-model="review.isActive"
            style="display: block"
            active-color="#409EFF"
            inactive-color="#aaa"
            active-text="Виден"
            inactive-text="Скрыт" />
        </el-form-item>
        <el-form-item label="ID">
          <el-input
            v-model.number="review.id"
            type="number" />
        </el-form-item>
        <el-form-item label="Рейтинг">
          <el-input
            v-model.number="review.rating"
            type="number" />
        </el-form-item>
        <el-form-item label="Достоинства">
          <el-input
            v-model="review.contentPlus"
            type="textarea"
            autosize />
        </el-form-item>
        <el-form-item label="Недостатки">
          <el-input
            v-model="review.contentMinus"
            type="textarea"
            autosize />
        </el-form-item>
        <el-form-item label="Коментарий">
          <el-input
            v-model="review.contentComment"
            type="textarea"
            autosize />
        </el-form-item>
        <el-form-item label="Дата Публикации">
          <el-input v-model="review.publishedAt" />
        </el-form-item>
        <el-form-item label="Автор ФИО">
          <el-input v-model="review.authorFio" />
        </el-form-item>
        <el-form-item label="Яндекс ID">
          <div class="form-item-desc">
            {{ review.tireReviewYandex && review.tireReviewYandex.yandexId }}
          </div>
        </el-form-item>
        <el-form-item label="Ссылка на яндекс">
          <div class="form-item-desc">
            <el-link
              v-if="review.tireReviewYandex"
              target="_blank"
              type="primary"
              :href="review.tireReviewYandex.link">
              {{ review.tireReviewYandex.link }}
            </el-link>
          </div>
        </el-form-item>
        <el-form-item label="">
          <el-button
            style="width: 100%"
            :type="isFormChanged ? 'primary' : 'success'"
            :disabled="isFormChanged"
            :loading="EDIT_REVIEW_REQUEST_STATUS === 'requested'"
            @click="saveData">
            Сохранить
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import isEqual from 'lodash.isequal';

export default {
  name: 'TireReview',
  data() {
    return {
      isEdit: true,
      review: {},
    };
  },
  computed: {
    ...mapGetters(['TIRE_REVIEW', 'EDIT_REVIEW_REQUEST_STATUS']),
    tireRewiewId() {
      return this.$route.params.tire_review_id;
    },
    isFormChanged() {
      return isEqual(this.review, this.TIRE_REVIEW);
    },
  },
  watch: {
    TIRE_REVIEW(state) {
      this.review = { ...state };
    },
    EDIT_REVIEW_REQUEST_STATUS(state) {
      if (state === 'success') {
        this.$notify({
          title: 'Готово',
          message: 'Отзыв успешно изменен',
          type: 'success',
        });
      }
    },
  },
  mounted() {
    this.getTireReview({ id: this.tireRewiewId });
  },
  methods: {
    ...mapActions(['getTireReview', 'setTireReviewDescription']),
    saveData() {
      this.setTireReviewDescription({
        id: this.tireRewiewId,
        review: this.review,
      });
    },
  },
};
</script>

<style lang="scss">
.tire-review {
  label.el-form-item__label {
    line-height: 1em;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .el-switch {
    height: 40px;
    display: flex !important;
    align-items: center;
  }
  .form-item-desc {
    display: flex;
    text-align: left;
    line-height: 1em;
    flex-direction: flex;
    align-items: center;
    min-height: 40px;
  }
}
</style>
